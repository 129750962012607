<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="{ ...$attrs, ...attrs }"
        v-on="on"
        :loading="loading"
        class="text-wrap"
        readonly
      >
        <template v-slot:append>
          <v-chip
            v-for="item of (multiple ? Object.values(selectedData) : [selectedData])"
            :key="item.id"
            close
            class="ma-2"
            color="primary"
            @click:close="multiple ? setItem(undefined) : removeItem(item)"
          >
            {{ item[itemText] }}
          </v-chip>
        </template>
      </v-text-field>
    </template>

    <div class="pa-4 d-flex flex-column" style="background-color: white">
      <v-text-field
        v-model="query"
        :loading="loading"
        clearable
        hide-details
        dense
        outlined
        label="Tìm kiếm"
        class="mb-2 flex-none"
      />

      <v-list class="flex overflow-auto">
          <v-list-item
            v-for="item of items"
            :key="item.id"
            @click="selectItem(item)"
          >
            <v-list-item-action v-if="multiple">
              <v-checkbox
                :input-value="item.id in selectedData"
                :ripple="false"
                readonly
                color="primary"
              />
            </v-list-item-action>

            <v-list-item-title>{{ item[itemText] }}</v-list-item-title>
          </v-list-item>
        </v-list>
    </div>
  </v-menu>
</template>

<script>
import { debounce } from "./debounce";
import request from "@/utils/request";

export default {
  props: {
    value: {
      type: [Array, Object, Number, String],
      default: undefined
    },

    multiple: {
      type: Boolean,
      default: false
    },

    url: {
      type: String,
      default: undefined
    },

    itemText: {
      type: String,
      default: "text"
    },

    returnObject: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      query: undefined,
      loading: false,
      loadingMore: false,

      items: [],
      perPage: 10,
      currentPage: 1,
      totalItems: 0,

      selectedData: undefined,
    }
  },

  computed: {
    selected: {
      get() { return this.value; },
      set(val) {
        this.$emit("input", val);
      }
    },

    loadMoreAvailable() {
      return this.currentPage * this.perPage < this.totalItems;
    },

    selectedDataText() {
      return this.multiple ? Object.values(this.selectedData).map(i => i[this.itemText]).join(", ") : this.selectedData[this.itemText];
    },
  },

  watch: {
    query(val) {
      this.onQueryChanged();
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.clear();
      this.fetchSelected();
      this.fetchData();
    },

    async fetchSelected() {
      if (!this.selected || Array.isArray(this.selected) && this.selected.length === 0) return;

      try {
        this.loading = true;

        const reses = await Promise.all(this.selected.map(id => request({
          url: this.url + "/" + id,
        })));

        const selected = {};

        for (const res of reses) {
          const item = res.data;
          selected[item.id] = item;
        }

        this.selected = selected;
      } finally {
        this.loading = false;
      }
    },

    onQueryChanged: debounce(function () {
      this.fetchData();
    }, 500),

    async fetchData() {
      try {
        this.loading = true;

        const params = {
          page: this.currentPage,
          page_size: this.perPage,
        };

        if (this.query) {
          params["search"] = this.query;
        }

        const res = await request({
          url: this.url,
          params,
        });

        this.items = res.data;
        this.totalItems = res.meta.total;
      } finally {
        this.loading = false;
      }
    },

    async fetchMore() {
      if (!this.loadMoreAvailable) return;

      try {
        this.loadingMore = true;

        const params = {
          page: this.currentPage + 1,
          page_size: this.perPage,
        };

        if (this.query) {
          params["search"] = this.query;
        }

        const res = await request({
          url: this.url,
          params,
        });

        this.currentPage += 1;
        this.items = this.items.concat(res.data);
        this.totalItems = res.meta.total;
      } finally {
        this.loadingMore = false;
      }
    },

    clear() {
      if (this.multiple) {
        this.selectedData = {};
        this.selected = [];
      } else {
        this.selectedData = undefined;
        this.selected = undefined;
      }
    },

    selectItem(item) {
      if (this.multiple) {
        if (item.id in this.selectedData) {
          this.removeItem(item);
        } else {
          this.addItem(item);
        }
      } else {
        this.setItem(item);
      }
    },

    setItem(item) {
      this.selectedData = item;
      this.selected = item ? item.id : undefined;
    },

    addItem(item) {
      this.$set(this.selectedData, item.id, item);

      this.selected.push(item.id);
    },

    removeItem(item) {
      this.$delete(this.selectedData, item.id);

      this.selected.splice(
        this.selected.indexOf(item.id), 
        1
      );
    }
  }
}
</script>
