<template>
  <v-dialog v-model="dialog" max-width="1280px">
    <v-card>
      <v-card-text>
        <v-data-table
          v-model="selectedStudentsData"
          :loading="loading"
          :headers="headers"
          :items="filteredStudents"
          :options.sync="options"
          :server-items-length="totalStudents"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
          }"
          item-key="id"
          show-select
        >
          <!-- Header, Create, Delete -->
          <template v-slot:top>
            <!-- Title -->
            <v-toolbar flat>
              <v-toolbar-title class="text-h3"
                >Quản lý danh sách Sinh viên</v-toolbar-title
              >
            </v-toolbar>

            <!-- Filters -->
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="selectedSubject"
                  :items="examination.subjects"
                  hide-details
                  return-object
                  item-text="fullName"
                  label="Học phần"
                  @change="fetchMetadata"
                />
              </v-col>

              <template v-if="!examination.isTesting">
                <v-col cols="12" md="3">
                  <v-select
                    v-model="selectedClassId"
                    :items="classIds"
                    hide-details
                    label="Lớp học"
                    @change="onFilterChanged"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="selectedStudyGroupName"
                    :items="studyGroupNames"
                    :disabled="!selectedClassId"
                    hide-details
                    label="Nhóm học"
                    @change="onFilterChanged"
                  />
                </v-col>
              </template>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="query"
                  hide-details
                  clearable
                  placeholder="Tìm kiếm theo Tên, MSSV, Email"
                />
              </v-col>
            </v-row>
          </template>

          <!-- No Data -->
          <template v-slot:no-data>
            <v-btn color="primary" :loading="loading" @click="fetchData">
              Refresh
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text @click="cancelStudentMgtDialog">HỦY</v-btn>
        <v-btn color="primary" @click="saveStudents">LƯU</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import request from "@/utils/request";
import { debounce } from "@/utils/debounce";

export default {
  props: {
    examination: {
      type: Object,
      required: true
    },

    room: {
      type: Object,
      required: true
    },

    visible: {
      type: Boolean,
      default: false
    },

    selectedStudents: {
      type: Array,
      default: undefined
    }
  },

  data() {
    return {
      loading: false,

      // Filters
      selectedClassId: undefined,
      metadata: {},
      selectedStudyGroupName: undefined,
      selectedSubject: undefined,

      query: undefined,

      // Table Data
      headers: [
        { text: "Lớp thi", value: "classId" },
        { text: "STT", value: "numbOrder" },
        { text: "Tên", value: "studentName" },
        { text: "MSSV", value: "studentCode" },
        { text: "Nhóm", value: "studyGroupName" }
      ],

      students: [],
      totalStudents: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: []
      },

      // Value
      selectedStudentsData: []
    };
  },

  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    },

    filteredStudents() {
      return this.students;
    },

    classIds() {
      if (!this.metadata.classIds) return [];

      return Object.keys(this.metadata.classIds);
    },

    studyGroupNames() {
      if (!this.selectedClassId) return [];

      return this.metadata.classIds[this.selectedClassId];
    }
  },

  watch: {
    query() {
      this.onQueryChanged();
    },

    dialog(val) {
      if (val) {
        this.initData();
        this.fetchMetadata();
      }
    },

    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },

  methods: {
    onQueryChanged: debounce(function() {
      this.onFilterChanged();
    }, 500),

    onFilterChanged() {
      this.options = {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: []
      };
      this.fetchData();
    },

    async fetchMetadata() {
      if (!this.selectedSubject) return;

      try {
        this.loading = true;

        const res = await request({
          url: "/supervisors/monitoring/students/metadata",
          params: {
            subjectId: this.selectedSubject.id
          }
        });

        this.metadata = res.data;

        if (this.selectedClassId === undefined)
          this.selectedClassId = Object.keys(this.metadata.classIds)[0];

        if (this.selectedStudyGroupName === undefined && !!this.selectedClassId)
          this.selectedStudyGroupName = this.metadata.classIds[
            this.selectedClassId
          ][0];

        this.onFilterChanged();
      } finally {
        this.loading = false;
      }
    },

    initData() {
      this.selectedStudentsData = this.selectedStudents
        ? [...this.selectedStudents]
        : [];
    },

    async fetchData() {
      if (!this.selectedSubject) return;

      if (
        !this.examination.isTesting &&
        (!this.selectedClassId || !this.selectedStudyGroupName)
      )
        return;

      try {
        this.loading = true;

        const { page, itemsPerPage, sortBy, sortDesc } = this.options;

        const params = {
          page,
          page_size: itemsPerPage,
          subjectId: this.selectedSubject.id,
          classId: this.selectedClassId,
          studyGroupName: this.selectedStudyGroupName
        };

        if (this.query) {
          params["search"] = this.query.trim();
        }

        if (sortBy && sortBy.length > 0) {
          params["order_by"] = sortBy[0];
          params["order_direction"] = sortDesc[0] ? "desc" : "asc";
        }

        const studentsRes = await request({
          url: `/supervisors/monitoring/students`,
          params
        });

        this.students = studentsRes.data;
        this.totalStudents = studentsRes.meta.total;
      } finally {
        this.loading = false;
      }
    },

    cancelStudentMgtDialog() {
      this.dialog = false;
    },

    saveStudents() {
      if (this.selectedStudentsData.length > 100) {
        return this.$snackbar(
          "Danh sách chỉ có thể chọn tối đa 100 người",
          "error"
        );
      }

      this.$emit("change", [...this.selectedStudentsData]);

      this.dialog = false;
    }
  }
};
</script>
